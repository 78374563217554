/* roboto-700 - latin */
@font-face {
    font-family: 'Roboto';
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    src: local(''),
    url('#{$site-url-prefix}/Fonts/Roboto/roboto-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$site-url-prefix}/Fonts/Roboto/roboto-v30-latin-700.woff') format('woff'), /* Modern Browsers */
}
