.fce-ot_pricingtable {
    h3 {
        font-size: 2rem;
        margin-top: 1rem;
    }


    a.btn {
        margin-top: 2rem;
    }

    .col:nth-child(2) .card h3,
    .col:nth-child(2) .card p,
    .col:nth-child(2) .card div {
        background-color: #00336c;
        color: #fff !important;
    }

    .col:nth-child(2) .card {
        box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.25);
        border: none;
    }

    .col:nth-child(2) .card li {
        background-color: #00447d;
    }

    .card-header {
        background-color: #ddd;
        border: none;
        text-align: right;
        font-weight: bold;
        padding: 0;
        margin: 0;

        span {
            background-color: red;
            text-align: right;
            color: #fff;
            font-weight: bold;
            padding: 8px 1.5rem;
            margin: 0;
            display: inline-block;
        }
    }

    .card-body ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            background-color: #eee;
            margin: 8px 0;
            padding: 8px;
        }
    }
}
