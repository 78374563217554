.ot-icon {
    margin-top: -0.2em;
}

.btn-primary {
    color: #fff;

    .ot-icon {
        fill: #fff;
        transition: fill .15s ease-in-out;
    }

    &:hover {
        text-decoration: none;
        color: darken(#fff, 10%);

        .ot-icon {
            fill: darken(#fff, 10%);
            transition: fill .15s ease-in-out;
        }
    }

}
.btn-outline-primary {
    .ot-icon {
        fill: var(--bs-btn-color);
        transition: fill .15s ease-in-out;
    }
    &:hover {
        .ot-icon {
            fill: var(--bs-btn-hover-color);
            transition: fill .15s ease-in-out;
        }
    }
}
