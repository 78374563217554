// deprecated
//#toTop {
//    z-index: 1000;
//    box-shadow: 2px 2px 4px #3B3B3B;
//    padding: 5px 10px 2px;
//    position: fixed;
//    bottom: 0;
//    right: 60px;
//    display: none;
//    font-size: 9px;
//    text-align: center;
//    cursor: pointer;
//    border: none;
//    background-color: rgba(98, 98, 98, 0.81);
//    color: #fff;
//    width: 60px;
//    height: 60px;
//    border-top-left-radius: 0.25rem;
//    border-top-right-radius: 0.25rem;
//}

// new
.scrollToTopBtn {
    position: fixed;
    bottom: 35px;
    right: 50px;
    display: block;
    padding: 2px;
    cursor: pointer;
    transform: translateY(100px);
    background-color: $primary;
    opacity: 0.8 !important;
    transition: transform 0.5s, opacity 0.3s;
    border-radius: 4px;
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.4);
}

.showScrollBtn {
    transform: translateY(0);
    opacity: 1;
}
