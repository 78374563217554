
.ot-contactbox strong {
  font-family: "Playfair Display", serif;
  color: #333;
  font-weight: 700;
  font-size: 20px;
  margin-right: 0.5rem;
}

.ot-contactbox-title {
  background: #ddd;
  padding: 25px 0;
}

.ot-contactbox-image {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 5%;
  height: auto;
  vertical-align: middle;
  width: 90%;
  border: 15px solid #c0b596;
  border-radius: 50% !important;
}

.ot-contactbox-title {
  text-align: center;
  font-size: 30px;
  color: #666;
  background-color: #ddd;
  margin: 0;
}

.ot-contactbox-bg {
  background-color: #f5f5f5;
}

.ot-contactbox li a,
.ot-contactbox li {
  color: #666;
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

@media (min-width: 767px) {
  .ot-contactbox-bg-right {
    border-left: 1rem solid #fff;
  }
}
