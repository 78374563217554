//:root {
//    @each $name, $value in $grid-breakpoints {
//        --bs-breakpoint-#{$name}: #{$value};
//    }
//}

/**
 * Smooth scrolling on the whole document
 */
html {
    scroll-behavior: smooth;
}
@media screen and (prefers-reduced-motion: reduce) {
    html {
        scroll-behavior: auto;
    }
}

html,
body {
    max-width: 100%;
    overflow-x: hidden;
}
