// Override Bootstrap 5 variables
$primary: #1c6bad;

$enable-rounded: false;
$enable-shadows: false;

$aspect-ratios: (
    "1x1": 100%,
    "4x3": calc(3 / 4 * 100%),
    "16x9": calc(9 / 16 * 100%),
    "21x9": calc(9 / 21 * 100%),
    "slider": calc(450/1296 * 100%) // 1296x450px
);
//.ratio-slider {
//calc(450/1296 * 100%; // 1296x450px
//}
