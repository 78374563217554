// Header top

.header-top {
    line-height: 1.5rem;
    padding: 0.8em 0 0.4em 0;
    vertical-align: middle;
    svg {
        fill: #fff;
    }
}

.header-top ul {
    padding: 0;
    margin: 0;

    li {
        list-style: none;
        float: left;
        a {
            margin-right: 1em;
        }
    }
}

.header-top .text-end ul {
    li a {
        margin-left: 1em;
        margin-right: 0;
    }
}

// Navbar

// Footer
#pagefooter {
    padding: 5rem 0;
    background-color: #2f5496;
    color: #fff;

    p:last-child {
        margin-bottom: 0;
    }

    a, strong {
        color: #fff;
    }
}
